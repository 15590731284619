import './sidebar.css'
import SidebarButton from '../sidebar-button/SidebarButton'
import SidebarInstruction from '../sidebar-instruction/SidebarInstruction'


function Sidebar({ sidebar }) {

  return (
    <div className="App">

      <div className={'sidebar'}>
        {sidebar.buttons.map(button => <SidebarButton
          key={sidebar.buttons.indexOf(button)} button={button}></SidebarButton>)}

        {sidebar.instructions.length > 0 &&
          <>
            <div className='instruction-container' style={{ marginTop: sidebar.instruction_container_margin_top }}>
              {sidebar.instructions.map(instruction =>
                <SidebarInstruction
                  key={sidebar.instructions.indexOf(instruction)} instruction={instruction}>
                </SidebarInstruction>)
              }
            </div>
          </>
        }

        {sidebar.instructions.length === 0 &&
          <>
            <div className='instruction-no-container'>
              <SidebarInstruction instruction={'No instructions'} instructionClassName={'sidebar-no-instruction'}> </SidebarInstruction>
            </div>
          </>
        }

      </div>
    </div>
  )
}

export default Sidebar