
import Page from '../../components/Page'
import sidebar from './sidebar.json'
import content from './content.json'

function AddImage() {

    const img1 = require('./assets/add-image1.png')
    const img2 = require('./assets/add-image2.png')
    const img3 = require('./assets/add-image3.png')
    const img4 = require('./assets/add-image4.png')

    content.images = [{
        imageName: img1,
        imageClassName: 'four-img',
        onClick: () => { }
    },
    {
        imageName: img2,
        imageClassName: 'four-img',
        onClick: () => { }
    },
    {
        imageName: img3,
        imageClassName: 'four-img',
        onClick: () => { }
    },
    {
        imageName: img4,
        imageClassName: 'four-img',
        onClick: () => { }
    }]



    return (<Page sidebar={sidebar} content={content}></Page>);
}

export default AddImage