import './sidebar-button.css'

import { useContext } from 'react';
import NavButtonsContext from '../../context/NavButtonsContext';
import routes from '../../routes/routes.json'

function SidebarButton({ button }) {

  const { navigatePageDown } = useContext(NavButtonsContext)
  
  const pageDownHandler = () => {
    if (routes[button] !== undefined)
    {
      navigatePageDown(routes[button])
    }
    else{
      navigatePageDown('comingsoon')
    }
  }

  return (<div className="sidebar-button" onClick={pageDownHandler}>{button} </div>)
}

export default SidebarButton