
import './video-content.css'

function VideoContent({video , title }) {
  return (
    
    <div className='video-container'>
      <h3 className='video-title'>{title}</h3>
      <video src={video} width="270" controls> </video>
    </div>

  )
}

export default VideoContent