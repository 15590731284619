import './content.css'
// import { useContext } from 'react'
// import NavButtonsContext from '../../context/NavButtonsContext'

import Page from '../../components/Page'
import sidebar from './sidebar.json'
import content from './content.json'


function AppHome() {

    //const {navigatedFrom, navigatePageDown} = useContext(NavButtonsContext)
    const img1 = require('./assets/nav24.png')

    content.images = [{
        imageName: img1,
        imageClassName: 'app-home-img1',
        // onClick: () => {navigatePageDown(navigatedFrom)}
    }]

    return (<Page sidebar={sidebar} content={content}></Page>);
}

export default AppHome;