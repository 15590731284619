import '../App.css';

import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Content from './content/Content';
import NavButtons from './nav-buttons/NavButtons';

function Page({ sidebar, content }) {

    return (
        <div className="App">
            <NavButtons></NavButtons>
            <Header title={content.title}></Header>
            <Sidebar sidebar={sidebar}></Sidebar>
            <Content content={content}></Content>
        </div>
    )
}

export default Page