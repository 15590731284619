import React from 'react'
import './about-page.css'

function AboutPage({ togglePage }) {

    const mobileApp = 'https://play.google.com/store/apps/details?id=com.gui4iot.guyp'
    const aut = require('../../assets/sid.png')

    return (
        <div className={'about-page'}>

            <div className='about-header'>
                <h2>About the Guide</h2>
            </div>

            <div className='about-content'>

                <div>
                    Welcome to the GUI Maker App User Guide!
                    <p>
                        This guide is designed to help you harness the full potential of the GUI Maker app.
                        Whether you're looking to create interactive dashboards with buttons, sliders, and toggles,
                        or display real-time images captured by remote webcams, this guide has you covered.
                        You'll also find step-by-step demos and project ideas to help you build innovative
                        projects for the internet, making it easier than ever to control and monitor your
                        devices remotely.
                    </p>
                    Let's dive in and start exploring the endless possibilities with the GUI Maker app!
                </div>
              
                 <hr />
                <div className='about-bio'>
                    <div className='about-bio-image'>  
                        <img className='about-bio-image' src={aut} alt="" />
                    </div>
                    <div className='about-bio-text'>
                        Said Chaida
                        <br />Sr. QA Engineer at Firstup
                    </div>
                </div>

                <h5>Links:</h5>

                <div className='about-links'>
                    Download the App from the &nbsp;
                    <a href={mobileApp} target='_blank' rel="noreferrer"> Play Store</a>
                </div>
            </div>

            <div className='about-footer'>
                <div className='sliding-window-button' onClick={togglePage}>Close</div>
            </div>
        </div>
    )
}

export default AboutPage