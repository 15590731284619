import './content.css'

import Page from '../../components/Page'
import sidebar from './sidebar.json'
import content from './content.json'

function AddFirebase() {

   const img1 = require('./assets/halt.png')

   content.images = [{
      imageName: img1,
      imageClassName: 'connect-firebase-halt',
      onClick: () => { }
   }]

   return (<Page sidebar={sidebar} content={content}></Page>);
}

export default AddFirebase;
