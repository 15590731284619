import { createContext, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import topRoutes from '../routes/top-routes.json'

export const NavButtonsContext = createContext()

export const NavButtonsContextProvider = ({ children }) => {

    const navigate = useNavigate('/')
    const location = useLocation()

    const [isNavigationButtonVisible, setIsNavigationButtonsVisible] = useState('hidden')
    const [isUpButtonVisible, setIsUpButtonsVisible] = useState('hidden')
    const [navigatedFrom, setNavigatedFrom] = useState('/')

    useEffect(() => {
        const navigationEntries = window.performance.getEntriesByType('navigation');
        if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
           
            let routeName = location.pathname.split('/')[1]
            setIsUpButtonsVisible('visible')
            if (routeName==='app') return
            setIsNavigationButtonsVisible('visible')
        }
    },[location.pathname]);

    const navigatePageDown = (routeName) => {

        if (routeName === 'app') {
            setIsNavigationButtonsVisible('hidden')
        }
        else{
            setIsNavigationButtonsVisible('visible')
        }
        console.log (routeName)
        setIsUpButtonsVisible('visible')
        navigate('/' + routeName)
    }

    const navigateHome = () => {

        setIsNavigationButtonsVisible('hidden')
        setNavigatedFrom(location.pathname.split('/')[1])
        navigate('/app')
    }

    const navigatePageUp = () => {

        let routeName = location.pathname.split('/')[1]

        if (routeName === 'app') {
            navigate('/')
            setIsUpButtonsVisible('hidden')
            return
        }

        if (routeName === 'comingsoon') {
            navigate('/app')
            setIsNavigationButtonsVisible('hidden')
            return
        }

        navigate(-1)

        if (topRoutes.indexOf(routeName) > -1) {
            setIsNavigationButtonsVisible('hidden')
        }
    }


    return (
        <NavButtonsContext.Provider value={{
            isNavigationButtonVisible, isUpButtonVisible, navigatedFrom,
            navigateHome,
            navigatePageDown,
            navigatePageUp
        }}>

            {children}
        </NavButtonsContext.Provider>)
}

export default NavButtonsContext