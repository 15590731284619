import './sidebar-instruction.css'

function SidebarInstruction({ instruction, instructionClassName }) 
{
  return (<div className={instructionClassName}>{instruction} </div>)
}

SidebarInstruction.defaultProps = {
  instructionClassName:"sidebar-instruction"
} 

export default SidebarInstruction