import { Routes, Route } from 'react-router-dom';
import { NavButtonsContextProvider } from './context/NavButtonsContext';
import { VideoContextProvider } from './context/VideoContext';

import Home from './pages/home/Home';
import AppHome from './pages/app-home/AppHome';

import ConnectFirebase from './pages/connect-firebase/ConnectFirebase';
import SetupUserAccess from './pages/connect-firebase/pages/setup-user-access/SetupUserAccess';
import ConnectDatabase from './pages/connect-firebase/pages/connect-database/ConnectDatabase';
import DisconnectDatabase from './pages/connect-firebase/pages/disconnect-database/DisconnectDatabase';

import ManageDashboards from './pages/manage-dashboards/ManageDashboards';
import AddDashboard from './pages/manage-dashboards/pages/add-dashboard/AddDashboard';
import HideDashboard from './pages/manage-dashboards/pages/hide-dashboard/HideDashboard';
import UnHideDashboard from './pages/manage-dashboards/pages/unhide-dashboard/UnHideDashboard';
import ReorderDashboards from './pages/manage-dashboards/pages/reorder-dashboards/ReorderDashboards';

import EditDashboard from './pages/edit-dashboard/EditDashboard';
import AddControls from './pages/edit-dashboard/pages/add-controls/AddControls';
import DeleteControls from './pages/edit-dashboard/pages/delete-controls/DeleteControls';
import ReorderControls from './pages/edit-dashboard/pages/reorder-controls/ReorderControls';

import DeleteDashboard from './pages/delete-dashboard/DeleteDashboard';
import ControlSettings from './pages/control-settings/ControlSettings';
import AddImage from './pages/add-image/AddImage';
import Videos from './pages/videos/Videos';

function App() {

  return (
    <NavButtonsContextProvider>
      <VideoContextProvider>
        <Routes>
          <Route exact path='/*' element={<Home />}> </Route>
          <Route path='/connecttofirebase' element={<ConnectFirebase />}> </Route>
          <Route path='/setupuseraccess' element={<SetupUserAccess />}> </Route>
          <Route path='/connectyourdatabase' element={<ConnectDatabase />}> </Route>
          <Route path='/disconnectyourdatabase' element={<DisconnectDatabase />}> </Route>
          <Route path='/managedashboards' element={<ManageDashboards />}> </Route>
          <Route path='/addadashboard' element={<AddDashboard />}> </Route>
          <Route path='/hideadashboard' element={<HideDashboard />}> </Route>
          <Route path='/unhideadashboard' element={<UnHideDashboard />}> </Route>
          <Route path='/reorderdashboards' element={<ReorderDashboards />}> </Route>
          <Route path='/app' element={<AppHome />}> </Route>
          <Route path='/editadashboard' element={<EditDashboard />}> </Route>
          <Route path='/addcontrols' element={<AddControls />}> </Route>
          <Route path='/deletecontrols' element={<DeleteControls />}> </Route>
          <Route path='/reordercontrols' element={<ReorderControls />}> </Route>
          <Route path='/deleteadashboard' element={<DeleteDashboard />}> </Route>
          <Route path='/controlsettings' element={<ControlSettings />}> </Route>
          <Route path='/addanimage' element={<AddImage />}> </Route>
          <Route path='/howtovideos' element={<Videos />}> </Route>
        </Routes>
      </VideoContextProvider>
    </NavButtonsContextProvider>
  );
}

export default App;
