import './content.css'

import Page from '../../../../components/Page'
import sidebar from './sidebar.json'
import content from './content.json'

function SetupUserAccess() {

   const img1 = require('./assets/sign-in-method.png')
   const img2 = require('./assets/add-user.png')
   const img3 = require('./assets/rules.png')

   content.images = [
      {
         imageName: img1,
         imageClassName: 'configure-database-img1',
         onClick: () => { }
      },
      {
         imageName: img2,
         imageClassName: 'configure-database-img2',
         onClick: () => { }
      },
      {
         imageName: img3,
         imageClassName: 'configure-database-img3',
         onClick: () => { }
      },
   ]
   
   return (<Page sidebar={sidebar} content={content}></Page>);
}

export default SetupUserAccess;
