import './content.css'
import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import NavButtonsContext from '../../context/NavButtonsContext';

import AboutPage from '../about-page/AboutPage.js';
import SlidingWindow from '../../components/sliding-window/SlidingWindow.js'

function Home() {
    const navigate = useNavigate('/')
    const { navigatePageDown } = useContext(NavButtonsContext)
    

    const alien = require('./assets/alien2.png')
    const img1 = require('./assets/dashboards-list.png')
    const img2 = require('./assets/living-room.png')
    const img3 = require('./assets/flight-deck.png')
    const img4 = require('./assets/garage.png')
    const img5 = require('./assets/office-light.png')
    const img6 = require('./assets/robot.png')

    const [isPageVisible, setPageVisible] = useState(false);
    const [image, setImage] = useState(img1)
    const [imageNum, setImageNum] = useState(1)

    const linkToConnectDevices = 'https://guimaker.pro'
    // const linkToPrivacyPolicy = 'https://guit4iot.netlify.app/policy'
    const linkToPrivacyPolicy = 'https://guimaker-privacy-plcy.netlify.app/'

    setTimeout(() => {

        if (imageNum === 1) {
            setImage(img2)
            setImageNum(2)
        }
        else if (imageNum === 2) {
            setImage(img3)
            setImageNum(3)
        }
        else if (imageNum === 3) {
            setImage(img4)
            setImageNum(4)
        }
        else if (imageNum === 4) {
            setImage(img5)
            setImageNum(5)
        }
        else if (imageNum === 5) {
            setImage(img6)
            setImageNum(6)
        }
        else if (imageNum === 6) {
            setImage(img1)
            setImageNum(1)
        }

    }, 3000)
    
    const togglePage = (event) => {
        event.preventDefault()
        setPageVisible(!isPageVisible);
        console.log(isPageVisible)
    }

    return (
        <>
            <div className='home-content'>
                <left-content>
                    {/* <img src={img1} className={'home-img'} alt="" /> */}
                    <img src={image} className={'home-img'} alt="" />
                </left-content>

                <right-content>
                    <div className={'home-texts-container'}>
                        Access devices over the internet with GUI Maker
                    </div>

                    <div className={'home-content-text'}>
                        {'> Create interactive dashboards featuring buttons, sliders, and toggles.'}
                        <br /> {'> Display an image captured by a remote webcam on your dashboard.'}
                        <br /> {'> Explore demos and build projects for the internet.'}

                    </div>
                    <img src={alien} width={270} height={300} alt="" />

                    <div className='button-content' onClick={() => navigatePageDown('app')}>Create Dashboards</div>
                    <div className='button-content' >
                        <a className='elink' href={linkToConnectDevices} target='_blank' rel="noreferrer">Start building Projects</a>
                    </div>

                    <SlidingWindow isVisible={isPageVisible}>
                        <AboutPage togglePage={togglePage}></AboutPage>
                    </SlidingWindow>
                </right-content>
            </div>

            <footer>
                <a className='elink' style={{ color: 'darkgray' }} href={linkToPrivacyPolicy} target='_blank' rel="noreferrer">
                    Privacy Policy
                </a>
                {' - '}
                <a className='elink' style={{ color: 'darkgray' }} href='/' onClick={togglePage}>
                    About
                </a>
            </footer>
        </>
    )
}

export default Home