
import Page from '../../../../components/Page'
import sidebar from './sidebar.json'
import content from './content.json'

function ConnectDatabase() {

    const img1 = require('./assets/connect1.png')
    const img2 = require('./assets/connect2.png')
    const img3 = require('./assets/connect3.png')

    content.images = [
        {
            imageName: img1,
            imageClassName: 'img',
            onClick: () => { }
        },
        {
            imageName: img2,
            imageClassName: 'img',
            onClick: () => { }
        },
        {
            imageName: img3,
            imageClassName: 'img',
            onClick: () => { }
        },
    ]

    return (<Page sidebar={sidebar} content={content}></Page>);
}

export default ConnectDatabase
