import './nav-buttons.css'

import { useContext } from 'react'
import NavButtonsContext from '../../context/NavButtonsContext'

function NavButtons() {

    const { navigateHome, navigatePageUp, isNavigationButtonVisible, isUpButtonVisible} = useContext(NavButtonsContext)

    const pageHomeHandler = () => {
        navigateHome()
    }

    const pageUpHandler = () => {
        navigatePageUp()
    }

    return (
        <div className={'container'}>
            <button onClick={pageHomeHandler} style={{ visibility: isNavigationButtonVisible }}>{'App Navigation'}</button>
            <button className="nav-button" onClick={pageUpHandler} style={{ visibility: isUpButtonVisible }}>{'<<'}</button>
        </div>)
}

export default NavButtons;