import './content.css'
import Image from '../image/Image'
import ContentText from '../content-text/ContentText'

function Content({ content }) {

  return (
    <>
      <div className={content.contentClassName}>

        {
          content.texts.length > 0 &&

          <div className={content.textsContainerClassName}>
            {content.texts.map(text => <ContentText key={content.texts.indexOf(text)} text={text} />)}
          </div>

        }

        {content.images.map(image => <Image key={content.images.indexOf(image)} image={image} />)}

      </div>
    </>
  )
}

export default Content