
import Page from '../../components/Page'
import sidebar from './sidebar.json'
import content from './content.json'

function DeleteDashboard() {

    const img1 = require('./assets/delete-dashboard1.png')
    const img2 = require('./assets/delete-dashboard2.png')
    const img3 = require('./assets/delete-dashboard3.png')
    const img4 = require('./assets/delete-dashboard4.png')

    content.images = [{
            imageName: img1,
            imageClassName: 'four-img',
            onClick: () => { }
        },
        {
            imageName: img2,
            imageClassName: 'four-img',
            onClick: () => { }
        },
        {
            imageName: img3,
            imageClassName: 'four-img',
            onClick: () => { }
        },
        {
            imageName: img4,
            imageClassName: 'four-img',
            onClick: () => { }
        }]

    return (<Page sidebar={sidebar} content={content}></Page>);
}

export default DeleteDashboard