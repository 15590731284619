import React, { createContext, useState } from 'react'

export const VideoContext = createContext()
export const VideoContextProvider = ({ children }) => {

    const [videoClassName, setVideoClassName] = useState('firebase_connect')

    return (
        <VideoContext.Provider value={{
             videoClassName,
             setVideoClassName
        }}>
            {children}
        </VideoContext.Provider>
    )
}
export default VideoContext