
import Page from '../../../../components/Page'
import sidebar from './sidebar.json'
import content from './content.json'

function ReorderControls() {

    const img1 = require('./assets/reorder-control1.png')
    const img2 = require('./assets/reorder-control2.png')
    
    content.images = [{
        imageName: img1,
        imageClassName: 'img',
        onClick: () => { }
    },
        {
        imageName: img2,
        imageClassName: 'img',
        onClick: () => { }}]

    return (<Page sidebar={sidebar} content={content}></Page>);
}

export default ReorderControls