
import Page from '../../../../components/Page'
import sidebar from './sidebar.json'
import content from './content.json'

function AddDashboard() {

    const img1 = require('./assets/add-dashboard1.png')
    const img2 = require('./assets/add-dashboard2.png')
    
    content.images = [{
        imageName: img1,
        imageClassName: 'img',
        onClick: () => { }
    },

    {
        imageName: img2,
        imageClassName: 'img',
        onClick: () => { }
    }
]

    return (<Page sidebar={sidebar} content={content}></Page>);
}

export default AddDashboard