
import Page from '../../../../components/Page'
import sidebar from './sidebar.json'
import content from './content.json'

function ReorderDashboards() {

    const img1 = require('./assets/reorder-dashboards1.png')
    const img2 = require('./assets/reorder-dashboards2.png')
    
    content.images = [{
        imageName: img1,
        imageClassName: 'img',
        onClick: () => { }
    },
    {
        imageName: img2,
        imageClassName: 'img',
        onClick: () => { }
    }]

    return (<Page sidebar={sidebar} content={content}></Page>);
}

export default ReorderDashboards