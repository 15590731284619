import './header.css'

function Header({title}) 

{
  return (<header className="App-header" > {title}  </header>)
}

Header.defaultProps = {
  pageTitle: ""
}

export default Header