import './content.css'

import Page from '../../components/Page'
import sidebar from './sidebar.json'
import content from './content.json'

function ManageDashboards() {

    const img = require('./assets/manage-dashboards.png')
    const alien = require('../../assets/alien.png')

    content.images = [{

        imageName: alien,
        imageClassName: 'alien-img-content',
        onClick: () => { }
    },
    {
        imageName: img,
        imageClassName: 'img',
        onClick: () => { }
    }]

    return (<Page sidebar={sidebar} content={content}></Page>);
}

export default ManageDashboards