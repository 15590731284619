import './content.css'

import Page from '../../components/Page'
import sidebar from './sidebar.json'
import content from './content.json'

function EditDashboard() {

    const img1 = require('./assets/manage-dashboards.png')
    const img2 = require('./assets/dashboard.png')
    const alien = require('../../assets/alien.png')

    content.images = [
        {
            imageName: alien,
            imageClassName: 'alien-img-content',
            onClick: () => { }
        },
        {
            imageName: img1,
            imageClassName: 'img-content',
            onClick: () => { }
        },
        {
            imageName: img2,
            imageClassName: 'img-content',
            onClick: () => { }
        }]

    return (<Page sidebar={sidebar} content={content}></Page>);
}

export default EditDashboard